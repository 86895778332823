input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: var(--shadow-1);
  background: var(--primary-300);
  border-radius: 0px;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: var(--shadow-1);
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: var(--primary-700);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--primary-600);
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: var(--shadow-1);
  background: var(--primary-300);
  border-radius: 0px;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: var(--shadow-1);
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: var(--primary-700);
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: var(--primary-300);
  border-radius: 2.6px;
  box-shadow: var(--shadow-1);
}
input[type="range"]::-ms-fill-upper {
  background: var(--primary-300);
  border-radius: 2.6px;
  box-shadow: var(--shadow-1);
}
input[type="range"]::-ms-thumb {
  box-shadow: var(--shadow-1);
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: var(--primary-700);
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: var(--primary-300);
}
input[type="range"]:focus::-ms-fill-upper {
  background: var(--primary-300);
}
