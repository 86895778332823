.icon-toggle-container {
  display: flex;
  gap: var(--spacing-sm);
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  flex: none;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--red-100);
  border: 2px solid var(--red-500);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 3px;
  bottom: 2.5px;
  background-color: var(--red-500);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  content: url("../images/x.svg");
}

input:checked + .slider {
  background-color: var(--green-100);
  border: 2px solid var(--green-500);
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
  background-color: var(--green-500);
  content: url("../images/check.svg");
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.icon-toggle-container-disabled {
  opacity: 0.4;
}
.icon-toggle-container-disabled .slider {
  background-color: var(--neutral-200);
  border: 2px solid var(--neutral-300);
}
.icon-toggle-container-disabled .slider:before {
  background-color: var(--neutral-300);
}

.icon-toggle-container-disabled p {
  color: var(--neutral-400);
}
