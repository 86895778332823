.master-grid-container {
  min-height: 250px;
  width: 100%;
  position: relative;
  margin-bottom: var(--spacing-2xl);
  min-width: 800px;
}

.grid-details-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  justify-content: center;
  display: flex;
  align-items: center;
}

.grid-details-content-container {
  width: 95%;
}

.dashboard-page .master-grid .ag-root-wrapper {
  border-radius: 0;
  border: 1.5px solid var(--primary-200);
}

.dashboard-page .master-grid .ag-header {
  background-color: var(--primary-700);
  color: var(--neutral-100);
}
.dashboard-page .master-grid .ag-header-cell,
.dashboard-page .master-grid .ag-icon-desc,
.dashboard-page .master-grid .ag-icon-asc {
  color: var(--neutral-100);
}
.dashboard-page .master-grid .ag-icon-filter {
  color: var(--primary-300);
}
.dashboard-page .master-grid .ag-header-cell-filter-button {
  margin-left: var(--spacing-xs);
}
.dashboard-page .master-grid .ag-header-cell {
  padding: 0 var(--spacing-2xs);
}
.dashboard-page .master-grid .ag-header-cell-text {
  font-family: var(--font-sans);
  font-weight: 600;
  font-size: var(--font-2xs);
}
.dashboard-page .master-grid .ag-header-cell-label {
  width: fit-content;
  flex: unset;
}
.dashboard-page .master-grid .ag-filter-active {
  background-color: var(--primary-200);
  color: var(--primary-500);
  box-shadow: 0 0 0 4px var(--primary-200);
}
.dashboard-page .master-grid .ag-cell {
  padding: 0 var(--spacing-2xs);
  white-space: normal;
  color: var(--primary-700);
  font-size: var(--font-2xs);
  border: none;
}

.dashboard-page .master-grid .ag-cell-value {
  display: flex;
  align-items: center;
  line-height: 1;
}
.dashboard-page .master-grid .ag-row:hover {
  background-color: var(--primary-100);
  cursor: pointer;
}
.dashboard-page .master-grid .ag-row {
  border-bottom: 1px solid var(--primary-200);
}
.dashboard-page .master-grid .ag-cell-focus:focus-within {
  border: unset;
  background-color: unset;
}
.dashboard-page .master-grid .ag-row .ag-column-first {
  max-width: 55px;
  background-color: var(--primary-100);
  display: flex;
  justify-content: center;
  border-right: 1px solid var(--primary-200);
}
.dashboard-page .master-grid .ag-row .ag-column-last {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-page .master-grid .ag-paging-panel {
  border-top-color: var(--primary-200);
  color: var(--primary-700);
  background-color: var(--primary-100);
}

.dashboard-page .master-grid .ag-cell-wrapper {
  height: 100%;
}

.ag-cell-label-container {
  justify-content: left;
}

.grid-arrow {
  padding: var(--spacing-2xs);
  background-color: var(--primary-200);
}

.send-reminder-button {
  background-color: var(--primary-600);
  border: unset;
  color: var(--neutral-100);
  height: 41px;
  width: 100%;
  font-family: var(--font-sans);
  line-height: 1;
  font-size: var(--font-2xs);
  font-weight: 300;
  transition: 0.5s ease;
  transition: 0.5s ease;
}

.send-reminder-button-disabled {
  background-color: var(--neutral-225);
  color: var(--neutral-400);
}

.send-reminder-button:hover {
  background-color: var(--primary-500);
  cursor: pointer;
  transition: 0.5s ease;
}
.send-reminder-button-disabled:hover {
  background-color: var(--neutral-225);
  color: var(--neutral-400);
  cursor: default;
}

/************************/
/***** DETAILS GRID *****/
/************************/

.dashboard-page .grid-details-container .ag-root-wrapper {
  border: none;
}

.dashboard-page .grid-details-container .master-grid .ag-row {
  border-bottom: 1px solid var(--primary-600);
}

.dashboard-page .grid-details-container .details-grid .ag-row:hover,
.dashboard-page .grid-details-container .master-grid .ag-row:hover {
  background-color: var(--neutral-100);
  cursor: default;
}

.dashboard-page .grid-details-container .details-grid .ag-row:hover::before,
.dashboard-page .grid-details-container .master-grid .ag-row:hover::before {
  background-color: var(--neutral-100);
}

.dashboard-page .details-grid .ag-header {
  background-color: var(--neutral-100);
  border: none;
}

.dashboard-page .details-grid .ag-header-cell {
  color: var(--primary-600);
}

.dashboard-page .details-grid .ag-header-cell-text {
  font-size: var(--font-3xs);
  font-weight: 700;
}

.dashboard-page .details-grid .ag-row .ag-column-first {
  max-width: unset;
  background-color: var(--neutral-100);
  display: unset;
  border-right: none;
}

.grid-cross {
  padding: var(--spacing-2xs);
  background-color: var(--red-100);
  color: var(--red-500);
}

.grid-cross:hover {
  cursor: pointer;
  opacity: 0.5;
}

/*************************/
/***** PRODUCTS PAGE *****/
/*************************/

.add-product-button {
  padding: var(--spacing-2xs);
  transition: 0.5s ease;
}

.add-product-button:hover {
  background-color: var(--primary-200);
  cursor: pointer;
  transition: 0.5s ease;
}

#admin-products-page .master-grid .ag-row .ag-column-first {
  max-width: unset;
}
#admin-products-page .master-grid .ag-row:hover,
#admin-products-page .master-grid .ag-row:hover::before {
  cursor: default;
  background-color: var(--neutral-100);
}

#admin-products-page .master-grid .products-menu-input-field {
  height: fit-content;
  width: fit-content !important;
  margin-right: auto;
  margin-top: 6px;
  display: flex;
  gap: var(--spacing-2xs);
}

#admin-products-page
  .master-grid
  .products-menu-input-field
  .editable-value-field:hover {
  cursor: text;
}
#admin-products-page .master-grid .ag-row-selected,
#admin-products-page .master-grid .ag-row-selected::before {
  background-color: var(--neutral-100);
}

#admin-products-page
  .master-grid
  .products-menu-input-field
  .editable-value-field {
  background-color: var(--primary-100);
  padding: var(--spacing-xs);
  border-bottom: 1px solid var(--primary-500);
}
#admin-products-page
  .master-grid
  .products-menu-input-field
  .editable-value-text {
  display: flex;
  gap: var(--spacing-2xs);
}

#admin-products-page
  .master-grid
  .products-menu-input-field
  .editable-value-text
  .editable-value-extra {
  color: var(--neutral-275);
}

#admin-products-page .master-grid .ag-checkbox-input-wrapper.ag-checked::after {
  color: var(--primary-500);
}

/*************************/
/*** TRANSLATIONS PAGE ***/
/*************************/

.dashboard-page .translations-grid .ag-row .ag-column-last {
  justify-content: unset;
} 

#admin-products-page .users-grid .ag-row .ag-column-first {
  justify-content: unset;
  border-right: unset;
  background-color: unset;
}