.error-screen-buttons-container {
  display: flex;
  gap: var(--spacing-2xs);
  overflow: hidden;
  height: fit-content;
}
.error-screen-buttons-container a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.error-screen-buttons-container a a {
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.error-screen-buttons-container .configurator-overview-button-title-status {
  height: 85%;
  display: flex;
  align-items: center;
}

.configurator-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
  overflow: hidden;
}

#drawing-container {
  position: relative;
}

#reset-aquarium-button,
#toggle-doors-button {
  position: absolute;
  z-index: 1;
  top: var(--spacing-sm);
  left: var(--spacing-sm);
  background-color: var(--primary-600);
  display: flex;
  gap: var(--spacing-2xs);
  padding: 0px var(--spacing-xs);
  align-items: center;
}

#reset-aquarium-button:hover,
#toggle-doors-button:hover {
  cursor: pointer;
  background-color: var(--primary-700);
}

#toggle-doors-button {
  bottom: var(--spacing-sm);
  right: var(--spacing-sm);
  left: unset;
  top: unset;
  padding: var(--spacing-2xs);
}

.control-center-container {
  margin: 3px;
  background-color: var(--neutral-100);
  width: calc(100% - 6px);
  /* nog te uit commentaar zetten: */
  flex-grow: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  /* nog te uit commentaar zetten: */
  overflow: hidden;
}

#control-center-body {
  display: flex;
  flex-direction: column;
  position: relative;
  /* nog te uit commentaar zetten: */
  flex-grow: 1;
  overflow-y: auto;
}

.control-center-fill {
  display: flex;
  flex-grow: 1;
}

.user-disclaimer-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--neutral-100);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-disclaimer-content-container {
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.user-disclaimer-content-container .user-agrees-to-terms-input,
.user-disclaimer-content-container .user-agrees-to-terms-input:hover {
  background-color: unset;
  font-size: var(--font-3xs);
  padding-left: 40px;
  display: inline;
}

.user-disclaimer-content-container .user-agrees-to-terms-input a {
  color: var(--primary-500);
}

.configurator-header-container {
  background: var(--primary-gradient-500);
  padding: var(--spacing-2xs);
  padding-left: var(--spacing-sm);
  box-shadow: var(--shadow-1);
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-sm);
}

#configurator-header-title {
  margin-top: auto;
  margin-bottom: auto;
}

#price-menu-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: auto;
}

#price-menu-container > p {
  margin-left: auto;
}

#first-price {
  color: var(--primary-300);
  text-decoration: line-through;
}

#discount-amount {
  color: var(--green-400);
  font-weight: 500;
}

#final-price {
  color: var(--primary-600);
  font-weight: 700;
  padding-left: var(--spacing-2xs);
  padding-right: var(--spacing-2xs);
  background-color: var(--neutral-100);
}
#configurator-steps-menu-container {
  box-shadow: var(--shadow-1);
  background: var(--neutral-gradient-100);
  /*overflow-y: auto;
  flex-shrink: 1;*/
}

.configurator-step-button-container,
.configurator-overview-button-container {
  display: flex;
  padding: var(--spacing-2xs);
  gap: var(--spacing-xs);
  position: relative;
  overflow: hidden;
  transition: 0.5s ease;
  z-index: 1;
  text-decoration: none;
}

.configurator-overview-button-container {
  background: var(--green-gradient-400);
  flex: none;
}

.configurator-overview-button-container:hover {
  cursor: pointer;
}

.configurator-overview-button-container::before {
  content: "";
  background: var(--green-gradient-400);
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.configurator-overview-button-container-ocean::before {
  background: var(--ocean-gradient-500);
}

.configurator-overview-button-container:hover::before {
  left: 0;
  transition: 0.5s ease;
}

.configurator-step-button-container-disabled {
  opacity: 0.2;
  background-color: var(--neutral-250);
}

.configurator-overview-button-container-disabled {
  opacity: 0.3;
}
.configurator-overview-button-container-disabled:hover {
  cursor: default;
}
.configurator-overview-button-container-disabled:hover::before {
  background: none;
}

.configurator-step-button-container:hover {
  cursor: pointer;
  transition: 0.5s ease;
}

.configurator-step-button-container-disabled:hover {
  cursor: default;
}

.configurator-step-button-container::before {
  content: "";
  background: var(--primary-gradient-200);
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  z-index: -1;
}

.configurator-step-button-container-disabled:before {
  content: "";
  background: none;
}

.configurator-step-button-container:hover::before {
  left: 0;
  transition: 0.5s ease;
}

.configurator-step-button-container:hover .configurator-step-button-steptext {
  color: var(--neutral-900);
  transition: 0.5s ease;
}

.configurator-step-button-container-disabled
  .configurator-step-button-steptext {
  color: unset;
}

.configurator-overview-button-text {
  color: var(--neutral-100);
}

.configurator-step-button-arrow,
.configurator-overview-button-arrow {
  height: 100%;
  width: 25px;
}

.configurator-step-button-content {
  display: flex;
  flex-direction: column;
}

.configurator-step-button-title-status {
  display: flex;
  gap: var(--spacing-2xs);
}

.configurator-step-button-status-icon {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.configurator-step-button-symbol,
.configurator-overview-button-symbol {
  position: absolute;
  bottom: -15px;
  right: -15px;
}

.configurator-menu-button-container:not(:first-child) {
  border-top: var(--spacing-3xs) solid var(--neutral-225);
}

.configurator-footer-container {
  display: flex;
  justify-content: space-between;
  background: var(--neutral-200);
  padding: var(--spacing-2xs);
}

.configurator-footer-container > div,
.configurator-footer-container > a {
  display: flex;
  gap: var(--spacing-xs);
  justify-content: center;
  text-decoration: none;
  color: var(--neutral-500);
}

.configurator-footer-container > a:hover {
  color: var(--neutral-300);
}

.configurator-footer-help-button:hover {
  cursor: pointer;
  opacity: 0.5;
}

.configurator-footer-figure {
  height: 25px;
  margin: 0;
}

.configurator-footer-img {
  height: 100%;
}

/***************************/
/**    STEP TEMPLATE      **/
/***************************/

.configurator-step-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: var(--neutral-100);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  right: 0;
}

.configurator-step-header {
  display: flex;
  padding: var(--spacing-xs);
  gap: var(--spacing-sm);
  border-bottom: 2px solid var(--primary-700);
  background-color: var(--primary-200);
  align-items: center;
  position: relative;
  overflow: hidden;
  flex: none;
}

.configurator-step-header-arrow {
  transition: 0.5s ease;
}

.configurator-step-header-arrow:hover {
  background-color: var(--primary-300);
  cursor: pointer;
  transition: 0.5s ease;
}

.configurator-step-header-content {
  display: flex;
  flex-direction: column;
}

.configurator-step-header-title-status {
  display: flex;
  gap: var(--spacing-2xs);
}

.configurator-step-header-symbol {
  position: absolute;
  bottom: -15px;
  right: -15px;
}

.configurator-step-body {
  flex-grow: 1;
}

.step-item-container {
  padding: var(--spacing-sm);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding-bottom: var(--spacing-lg);
  background-color: var(--neutral-100);
  overflow: hidden;
}
.step-item-container:not(:last-child) {
  border-bottom: 2px solid var(--neutral-200);
}

.step-item-header {
  display: flex;
  gap: var(--spacing-2xs);
}

.step-item-question {
  color: var(--primary-300);
  transition: 0.5s ease;
  align-self: center;
}
.step-item-question:hover {
  color: var(--primary-600);
  cursor: pointer;
  transition: 0.5s ease;
}

.step-item-helptext-container {
  background-color: var(--primary-100);
  padding: var(--spacing-2xs);
  display: flex;
  justify-content: space-between;
}

.step-item-helptext-cross {
  background-color: var(--primary-200);
  top: var(--spacing-2xs);
  right: var(--spacing-2xs);
  transition: 0.5s ease;
  flex: none;
}

.step-item-helptext-cross:hover {
  cursor: pointer;
  background-color: var(--neutral-100);
  transition: 0.5s ease;
}

.configurator-step-container .step-item-container-issue {
  background-color: var(--red-100);
  border-top: 2px solid var(--red-500);
  border-bottom: 2px solid var(--red-500);
}

.step-item-issue-container {
  display: flex;
  gap: var(--spacing-2xs);
  align-items: center;
}

.step-item-errortext {
  line-height: 1.25;
}

.step-item-issue-icon {
  flex: none;
}

/*******************************/
/**    DIMENSIONS SLIDER      **/
/*******************************/

.dimensions-step-figure {
  margin: 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.dimensions-step-img {
  width: 100%;
}

.dimension-slider-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: center;
  gap: var(--spacing-2xs);
  max-width: 400px;
}

.dimension-slider-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dimension-slider-title {
  font-size: var(--font-sm);
  font-weight: 400;
  color: var(--primary-700);
  margin: 0;
}

.dimension-slider-number-input {
  background-color: var(--primary-100);
  border: none;
  border-bottom: 2px solid var(--primary-700);
  padding: var(--spacing-xs);
  font-size: var(--font-xs);
  color: var(--primary-700);
  position: relative;
}

.dimension-slider-number-input-false {
  background-color: var(--red-100);
  border-bottom: 2px solid var(--red-500);
}

.dimension-slider-number-input-false-special {
  background-color: var(--neutral-100);
}

.dimension-slider-range-input {
  accent-color: var(--primary-700);
}

.dimension-slider-unit {
  display: flex;
  align-items: center;
  gap: var(--spacing-2xs);
}

.dimension-slider-unit > p {
  flex: none;
}

/************************/
/**    WATER TYPE      **/
/************************/

.watertype-selection-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  color: var(--primary-500);
  background-color: var(--primary-100);
}

.watertype-button-container > p {
  width: 100%;
  margin: 0;
  text-align: center;
  align-self: center;
  padding: var(--spacing-2xs) 0;
  font-weight: 600;
}

.watertype-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0.3;
  transition: 0.5s ease;
}

.watertype-button-container-active {
  color: var(--neutral-100);
  background-color: var(--primary-500);
  border: 4px solid var(--primary-100);
  opacity: 1;
}
.watertype-button-container-active p {
  color: var(--neutral-100);
}

.watertype-button-container:hover {
  background-color: var(--primary-200);
  opacity: 1;
  cursor: pointer;
  transition: 0.5s ease;
}

/***************************/
/**    IMAGE OPTIONS      **/
/***************************/

.optionlist-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  transition: 0.5s ease;
  width: 100%;
}

.imageoption-container {
  display: flex;
  flex-direction: column;
  width: 48%;
  max-width: 150px;
  background-color: var(--primary-600);
  box-shadow: var(--shadow-1);
  border: 1px solid var(--primary-500);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: 0.5s ease;
  opacity: 1;
}

.optionlist-container .optionlist-button-container .imageoption-container {
  width: 96%;
}

.imageoption-container-active {
  border-width: 5px;
}

.imageoption-container-inactive {
  opacity: 0.6;
}

.imageoption-container:hover {
  cursor: pointer;
  border-width: 5px;
  opacity: 1;
}
.imageoption-container-inactive:hover {
  opacity: 1;
  border-width: 1px;
}

.imageoption-container-default {
  opacity: 1;
}

.imageoption-container-default:hover {
  border-width: 5px;
}

.imageoption-input {
  display: none;
}

.imageoption-figure {
  width: 100%;
  aspect-ratio: 1 / 1;
  margin: 0;
  flex-grow: 1;
  overflow: hidden;
}

.imageoption-img {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  -o-object-fit: cover;
  object-fit: cover;
}

.imageoption-label {
  color: var(--neutral-100);
  font-weight: 300;
  font-size: var(--font-xs);
  text-align: center;
  padding: var(--spacing-xs) var(--spacing-2xs);
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-2xs);
}

.imageoption-label:hover {
  cursor: pointer;
}

.textures-modal-container {
  background-color: rgb(0, 0, 0, 0.6);
  height: calc(100% - 48px);
  position: absolute;
  z-index: 2;
  display: flex;
  padding: var(--spacing-md);
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}

.textures-modal-content-container {
  background-color: var(--neutral-100);
  max-width: 1024px;
  width: 100%;
  margin: auto;
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.textures-modal-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.optionlist-container-texturemodal {
  max-width: unset;
  display: flex;
  grid-template-columns: unset;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 60vh;
}

.optionlist-container-texturemodal .imageoption-container {
  max-width: 150px;
}

.texture-modal-arrow {
  transition: 0.5s ease;
}

.texture-modal-arrow:hover {
  cursor: pointer;
  background-color: var(--neutral-250);
  transition: 0.5s ease;
}

/***************************/
/****** WINDOW PAINT *******/
/***************************/

.window-paint-menu-title {
  color: var(--primary-300);
  margin: 0;
}

.window-paint-menu-container {
  display: flex;
  gap: var(--spacing-md);
  flex-wrap: wrap;
}

.paint-option-container {
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
  border: 2px solid var(--neutral-225);
  border-radius: 50px;
  padding: var(--spacing-xs);
  transition: 0.5s ease;
  opacity: 0.5;
}

.paint-option-container:hover {
  cursor: pointer;
  transition: 0.5s ease;
  opacity: 1;
}

.paint-option-container > label:hover {
  cursor: pointer;
}

.window-paint-preview-black,
.window-paint-preview-blue,
.window-paint-preview-transparent,
.coverpanels-color-preview-black,
.coverpanels-color-preview-gray,
.coverpanels-color-preview-white,
.coverpanels-color-preview-antraciet {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: var(--neutral-900);
  order: -1;
}

.window-paint-preview-blue {
  background-color: var(--primary-500);
}
.window-paint-preview-transparent {
  background-image: linear-gradient(
    135deg,
    var(--neutral-200),
    var(--neutral-250)
  );
}
.coverpanels-color-preview-white {
  background-color: var(--neutral-100);
  border: 1px solid var(--neutral-400);
}
.coverpanels-color-preview-antraciet {
  background-color: var(--neutral-400);
}

.coverpanels-color-preview-gray {
  background-color: var(--neutral-250);
}

.window-paint-option {
  display: none;
}

.paint-option-active {
  border-color: var(--primary-500);
  opacity: 1;
  transition: 0.5s ease;
}

.window-paint-side-options-container {
  display: flex;
  gap: var(--spacing-sm);
  flex-wrap: wrap;
}

.window-paint-side-option {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* border: 1px solid var(--neutral-250); */
  padding: var(--spacing-2xs);
  padding-right: var(--spacing-xs);
  padding-left: calc(25px + var(--spacing-xs));
  transition: 0.5s ease;
  background-color: var(--neutral-200);
}

.window-paint-side-option:hover {
  background-color: var(--primary-200);
  transition: 0.5s ease;
}

.window-paint-side-option-active {
  background-color: var(--primary-500);
  color: var(--neutral-100);
}

.window-paint-side-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: var(--spacing-2xs);
  left: var(--spacing-2xs);
  height: 25px;
  width: 25px;
  border: 2px solid var(--neutral-250);
  box-sizing: border-box;
}

.window-paint-side-option input:hover input ~ .checkmark {
  background-color: #ccc;
}

.window-paint-side-option input:checked ~ .checkmark {
  background-color: var(--primary-500);
  border: none;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.window-paint-side-option input:checked ~ .checkmark:after {
  display: block;
}

.window-paint-side-option .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.furniture-sliders-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.furniture-sliders-container .icon-toggle-container {
  width: 80%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.webshop-item-container {
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-100);
  box-shadow: var(--shadow-1);
  width: 100%;
  max-width: 170px;
}

.swiper {
  padding-bottom: 40px !important;
  width: 100% !important;
  overflow: visible !important;
}

.swiper-slide {
  display: flex !important;
  justify-content: center !important;
}

.webshop-item-figure {
  margin: 0;
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  max-height: max-content;
  overflow: hidden;
}

.webshop-item-image {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.webshop-item-tag {
  background-color: var(--green-100);
  color: var(--green-500);
  font-size: var(--font-3xs);
  display: flex;
  gap: var(--spacing-2xs);
  width: fit-content;
  padding: 0 var(--spacing-2xs);
  font-weight: 500;
  position: absolute;
  bottom: var(--spacing-xs);
  left: var(--spacing-xs);
}

.webshop-item-content-container {
  padding: var(--spacing-xs);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  margin-bottom: auto;
}

.webshop-item-title {
  margin: 0;
  color: var(--primary-800);
  font-weight: 700;
  font-size: var(--font-2xs);
}

.webshop-item-price {
  font-size: var(--font-xs);
  font-weight: 700;
  color: var(--primary-800);
}

.webshop-item-price-excl-btw {
  font-size: var(--font-3xs);
  color: var(--neutral-300);
}

.webshop-item-info {
  color: var(--neutral-300);
  font-size: var(--font-3xs);
  display: flex;
  gap: var(--spacing-3xs);
  align-items: center;
  margin-top: auto;
  transition: 0.5s ease;
}

.webshop-item-info:hover {
  cursor: pointer;
  color: var(--neutral-250);
  transition: 0.5s ease;
}

.webshop-item-add-button {
  display: flex;
  gap: var(--spacing-2xs);
  background-color: var(--primary-500);
  color: var(--neutral-100);
  padding: var(--spacing-xs);
  font-weight: 600;
  font-size: var(--font-xs);
  align-items: center;
  /* transition: 0.5s ease; */
}

.webshop-item-add-button:hover {
  cursor: pointer;
  background-color: var(--primary-300);
  /* transition: 0.5s ease; */
}

.webshop-item-adding-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.webshop-item-adding-content-container {
  height: 100%;
  width: 100%;
  background-color: var(--primary-200);
  display: flex;
  align-items: center;
  justify-content: center;
}

.webshop-item-adding-button-container {
  display: flex;
  justify-content: center;
  width: 80%;
  max-width: 100px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.webshop-item-adding-button-container div {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-3xs);
  box-sizing: content-box;
  font-weight: 600;
  font-size: var(--font-xs);
  border-top: 1px solid var(--primary-300);
  border-bottom: 1px solid var(--primary-300);
  background-color: var(--neutral-100);
}

.webshop-item-adding-button-container .webshop-item-adding-button {
  background-color: var(--primary-500);
  border: 1px solid var(--primary-300);
  color: var(--neutral-100);
  transition: 0.5s ease;
}

.webshop-item-adding-button:hover {
  background-color: var(--primary-600);
  transition: 0.5s ease;
  cursor: pointer;
}

.webshop-item-button-trash {
  background-color: var(--red-100);
  color: var(--red-500);
  padding: var(--spacing-xs);
  height: 24px;
  /* transition: 0.5s ease; */
}

.webshop-item-button-trash:hover {
  cursor: pointer;
  background-color: var(--red-500);
  color: var(--red-100);
  /* transition: 0.5s ease; */
}

.product-info-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  justify-content: space-between;
}

.help-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  justify-content: space-between;
}

.product-info-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.help-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.help-text-container h3 {
  margin: 0;
  font-size: var(--font-xs);
  font-weight: 700;
}

.help-text-container p {
  margin: 0;
  font-size: var(--font-xs);
}

.product-info-text-container p {
  margin: 0;
  font-size: var(--font-xs);
  font-weight: 300;
}

.help-modal-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}
.help-modal-list li {
  display: flex;
  gap: var(--spacing-2xs);
  align-items: center;
  font-weight: 500;
}

.help-modal-list li a {
  text-decoration: none;
  color: var(--neutral-900);
}

.product-info-text-container .product-info-recommanded-text {
  background-color: var(--green-100);
  color: var(--green-500);
  padding: var(--spacing-2xs);
  border: 1px solid var(--green-500);
  font-size: var(--font-2xs);
}

.product-info-text-container .webshop-item-price {
  font-size: var(--font-sm);
}
.product-info-text-container .webshop-item-price-excl-btw {
  font-size: var(--font-2xs);
}

.product-info-figure {
  width: 100%;
  margin: 0;
  order: -1;
}
.product-info-figure img {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  -o-object-fit: cover;
  object-fit: cover;
}

/****************************/
/********  OVERVIEW  ********/
/****************************/

.webshop-item-extra-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.overview-container {
  position: absolute;
  z-index: 3;
  width: 100%;
  min-height: 100%;
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-md);
}

.overview-content-container {
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 1200px;
  overflow: hidden;
  height: fit-content;
  position: relative;
  gap: var(--spacing-sm);
}

.overview-container-item {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.overview-snapshot-figure {
  margin: 0;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-image: linear-gradient(
      140deg,
      var(--primary-500) 0%,
      var(--primary-200)
    )
    1;
  border-width: 2px;
  border-style: solid;
}
.overview-snapshot-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.overview-form-container {
  background-color: var(--primary-700-50);
  border-image: linear-gradient(
      140deg,
      var(--primary-300) 0%,
      var(--primary-700)
    )
    1;
  border-width: 2px;
  border-style: solid;
  display: flex;
  flex-direction: column;
}

.overview-personal-data-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: var(--spacing-xs);
  row-gap: var(--spacing-3xs);
  padding: var(--spacing-sm);
}

.overview-form-row-1-3 {
  grid-column-start: 1;
  grid-column-end: 4;
}
.overview-form-row-4-3 {
  grid-column-start: 4;
  grid-column-end: 7;
}
.overview-form-row-1-2 {
  grid-column-start: 1;
  grid-column-end: 3;
}
.overview-form-row-1-6 {
  grid-column-start: 1;
  grid-column-end: 7;
}

.overview-form-row-3-4 {
  grid-column-start: 3;
  grid-column-end: 7;
}
.overview-form-row-3-1 {
  grid-column-start: 4;
  grid-column-end: 5;
}
.overview-form-row-4-2 {
  grid-column-start: 5;
  grid-column-end: 7;
}

.overview-delivery-time {
  font-size: var(--font-2xs);
  font-weight: 300;
  color: var(--neutral-100);
  background-color: var(--primary-600);
  padding: 0 var(--spacing-2xs);
  width: fit-content;
}

.client-remarks-input {
  padding-top: var(--spacing-md);
}

.overview-buttons-container {
  display: flex;
  width: 100%;
}

.overview-actionbutton-container {
  width: 50%;
}
.overview-actionbutton-container {
  width: 50%;
}

.overview-animation-container {
  position: absolute;
  left: 0;
  bottom: 0;
}

.overview-goback-arrow {
  position: absolute;
  left: 10px;
  top: 10px;
  transition: 0.5s ease;
  padding: var(--spacing-2xs);
  text-decoration: none;
  z-index: 1;
  background-color: var(--primary-100);
  height: 32px;
}

.overview-goback-arrow:hover svg {
  background-color: var(--primary-300);
  cursor: pointer;
  transition: 0.5s ease;
}

.overview-delivery-container {
  grid-column-start: 1;
  grid-column-end: 7;
  margin-top: var(--spacing-lg);
}

.option-menu-title {
  color: var(--primary-100);
  padding: 0;
  margin: 0;
  font-size: var(--font-xs);
  font-weight: 700;
  padding-bottom: var(--spacing-2xs);
}

.option-menu-label {
  background-color: unset;
  text-align: left;
  justify-content: left;
}

.option-menu-label:hover {
  background-color: var(--primary-600);
}

.overview-table {
  width: 100%;
  background-color: var(--primary-700-50);
  border-image: linear-gradient(
      140deg,
      var(--primary-200) 0%,
      var(--primary-500)
    )
    1;
  border-width: 2px;
  border-style: solid;
  color: var(--neutral-100);
}

.overview-table .overview-table-title,
.overview-table-footer-title {
  font-size: var(--font-sm);
  font-weight: 700;
  background-image: linear-gradient(
    135deg,
    var(--primary-700),
    var(--primary-600)
  );
  width: 100%;
}

.overview-table .overview-table-title th,
.overview-table .overview-table-footer-title td {
  padding: var(--spacing-sm);
  border: none;
}

.overview-table .overview-table-footer-title td {
  text-align: left;
  font-weight: 600;
}

.overview-table-subtitle {
  background-image: linear-gradient(
    135deg,
    var(--primary-200-25),
    var(--primary-300-25)
  );
  width: 100%;
  font-size: var(--font-xs);
  font-weight: 500;
  border-image: linear-gradient(
      140deg,
      var(--primary-300) 0%,
      var(--primary-700)
    )
    1;
  border-width: 2px;
  border-style: solid;
}
.overview-table .overview-table-tbody tr {
  text-align: left;
  padding-top: var(--spacing-2xs);
}
.overview-table th {
  width: 50%;
  padding: var(--spacing-2xs) var(--spacing-sm);
  /* border-top: 2px solid var(--primary-700); */
}
.overview-table td {
  width: 50%;
  padding: 0 var(--spacing-xl);
  font-size: var(--font-xs);
  font-weight: 300;
  border-top: 1px solid var(--primary-300-50);
}

.overview-table-extra-info td {
  font-size: var(--font-2xs);
  font-style: italic;
  padding-left: var(--spacing-3xl);
  font-weight: 300;
  color: var(--primary-200);
  padding-bottom: var(--spacing-2xs);
  border-top: none;
}

.overview-table-td-no-border td {
  border-top: none;
}

.overview-offerte-send-message-container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-2xs);
  width: 100%;
  padding-left: var(--spacing-sm);
  background-color: var(--primary-300-50);
}

@media only screen and (min-width: 1024px) {
  .configurator-container {
    flex-direction: row;
  }

  #reset-aquarium-button {
    top: unset;
    bottom: var(--spacing-sm);
  }

  #toggle-doors-button {
    right: var(--spacing-sm);
    top: unset;
  }

  .webshop-item-container {
    width: 48%;
  }

  .help-text-container {
    width: 47%;
  }

  .overview-container {
    padding-top: var(--spacing-xl);
    padding-bottom: var(--spacing-xl);
  }

  .overview-content-container {
    gap: unset;
    flex-direction: row;
    justify-content: space-between;
  }

  .overview-container-item {
    width: 48%;
  }

  .overview-snapshot-figure {
    height: 300px;
  }

  .overview-form-container {
    height: fit-content;
  }

  .product-info-content-container {
    flex-direction: row;
  }
  .product-info-text-container {
    width: 55%;
  }
  .product-info-figure {
    width: 40%;
    order: 0;
  }
}
