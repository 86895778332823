.order-message-screen-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.order-message-screen {
  padding: 0;
  height: 100%;
}

.order-message-screen .order-message-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 100px;
}

.order-message-animation-container {
  width: 100%;
  height: 75%;
  display: flex;
  order: 1;
  position: absolute;
  bottom: 0;
}

.order-message-animation-container figure {
  margin: 0;
  margin-top: auto;
}

.order-message-animation-container img {
  width: 100%;
}

.order-message-screen-content-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.order-message-screen-content-column {
  width: 60%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xl);
}

.order-message-screen-content-column:last-child {
  width: 90%;
}

.order-process-container {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.order-process-step {
  display: flex;
  gap: var(--spacing-lg);
  align-items: center;
}

@media only screen and (min-width: 1024px) {
  .order-message-screen .order-message-container {
    flex-direction: row;
    padding: 0;
  }

  .order-message-animation-container {
    width: 25%;
    height: 75%;
    margin-top: auto;
    order: 0;
    position: unset;
  }

  .order-message-screen-content-column {
    width: 60%;
  }

  .order-message-screen-content-column:last-child {
    width: 45%;
    max-width: 300px;
  }
}
