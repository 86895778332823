.configurator-navigator-container {
  background-color: var(--primary-800);
  display: flex;
  justify-content: space-between;
}

.configurator-navigator-button {
  display: flex;
  background-color: var(--primary-600);
  color: var(--neutral-100);
  padding: var(--spacing-2xs) var(--spacing-md);
  transition: 0.5s ease;
  align-items: center;
  gap: var(--spacing-xs);
}

.configurator-navigator-button:hover {
  background-color: var(--primary-700);
  cursor: pointer;
  transition: 0.5s ease;
}

.configurator-navigator-button .configurator-navigator-button-title {
  font-weight: 600;
}

.configurator-navigator-button-to-overview {
  background-image: linear-gradient(to left, #5bdd2c, #39b500);
  transition: 0.5s ease;
}
.configurator-navigator-button-to-overview:hover {
  transition: 0.5s ease;
  background-image: linear-gradient(to left, #5bdd2c, #35a600);
}
