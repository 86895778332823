.admin-menu-container {
  background-color: var(--primary-700);
  background-image: url("../images/landingpage-background.jpg");
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: var(--spacing-lg);
  padding-bottom: var(--spacing-lg);
  color: var(--neutral-100);
  gap: var(--spacing-lg);
  flex-direction: column;
  box-shadow: var(--shadow-1);
}

.admin-menu-header {
  width: 90%;
  margin: auto;
}

.admin-menu-header > div {
  font-weight: 600;
  font-size: var(--font-md);
  margin: 0;
}
.admin-menu-header > h2 {
  font-weight: 300;
  font-size: var(--font-sm);
  margin: 0;
}

.admin-menu-lists-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  gap: var(--spacing-sm);
  row-gap: var(--spacing-xl);
}

.admin-menu-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 48%;
  gap: var(--spacing-sm);
}

.admin-menu-list li a {
  text-decoration: none;
  color: var(--neutral-100);
  font-size: var(--font-xs);
  font-weight: 600;
}

.admin-menu-list-item {
  line-height: 0.9;
}

.admin-menu-list-item > ul {
  padding-left: var(--spacing-sm);
  padding-top: var(--spacing-sm);
}
.admin-menu-list-item > ul > li > a {
  font-weight: 300;
}

.admin-menu-list li a:hover {
  text-decoration: underline;
}

.dashboard-page {
  width: 90%;
  margin: auto;
  max-width: 1024px;
  padding-top: var(--spacing-3xl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  position: relative;
}

.dashboard-goback-arrow {
  position: absolute;
  left: -50px;
  transition: 0.5s ease;
  padding: 0;
  text-decoration: none;
}
.dashboard-goback-arrow:hover svg {
  background-color: var(--primary-100);
  cursor: pointer;
  transition: 0.5s ease;
}

.dashboard-page-header {
  display: flex;
  justify-content: space-between;
}

.admin-landing-content-container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

.admin-landing-insights-container {
  display: flex;
}

.dashboard-insight-container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-sm);
  color: var(--neutral-300);
}

.dashboard-insight-content-horizontal {
  display: flex;
  align-items: baseline;
  line-height: 100px;
  align-items: center;
}

.dashboard-insight-number {
  font-size: var(--font-3xl);
  font-weight: 200;
}

.dashboard-insight-x {
  font-size: var(--font-2xl);
  font-weight: 200;
  line-height: 60px;
  margin-top: auto;
}

.dashboard-insight-content-vertical {
  display: flex;
  flex-direction: column;
  line-height: 15px;
}

.dashboard-insight-title {
  font-size: var(--font-xs);
  font-weight: 400;
}

.dashboard-insight-subtitle {
  font-size: var(--font-2xs);
  font-weight: 600;
}

/*****************************/
/******* QUICK FILTERS *******/
/*****************************/

.quick-filters-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  width: 50%;
}

.quick-filters-title {
  margin: 0;
  color: var(--neutral-300);
  font-size: var(--font-2xs);
  font-weight: 700;
}

.quick-filters-selection-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-2xs);
}

.quick-filter-option {
  background-color: var(--primary-100);
  padding: var(--spacing-2xs) var(--spacing-sm);
  color: var(--primary-300);
  font-size: var(--font-xs);
  font-weight: 500;
  transition: 0.5s ease;
}

.quick-filter-option:hover {
  background-color: var(--primary-200);
  color: var(--primary-600);
  cursor: pointer;
}

.quick-filter-option-checked {
  background-color: var(--primary-500);
  color: var(--neutral-100);
  transition: 0.5s ease;
}

.quick-filter-option-checked:hover {
  background-color: var(--primary-500);
  color: var(--neutral-100);
}

.quick-filter-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/*********************/
/*** SEARCH BAR ******/
/*********************/

.admin-dashboard-searchbar-container {
  position: relative;
  width: fit-content;
  margin-top: auto;
}

.admin-dashboard-searchbar-container input {
  background-color: var(--primary-100);
  color: var(--primary-600);
  border: none;
  padding: var(--spacing-xs) var(--spacing-md);
  width: 250px;
  font-size: var(--font-xs);
  border-bottom: 2px solid var(--primary-700);
}

.admin-dashboard-searchbar-container input::placeholder {
  font-size: var(--font-xs);
  color: var(--primary-300);
}

.admin-dashboard-searchbar-container input:focus {
  outline: none;
}

.admin-dashboard-searchbar-container-filledin input {
  background-color: var(--primary-200);
  color: var(--primary-700);
}

.admin-dashboard-searchbar-icon {
  position: absolute;
  right: 3px;
  top: 5px;
}

/*********************/
/*** PRODUCT MENU ****/
/*********************/

.products-menu-list-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.products-menu-list-container .products-menu-container:last-child {
  border: none;
}

.products-menu-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  border-bottom: 2px solid var(--neutral-200);
  padding-bottom: var(--spacing-md);
}

.products-menu-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.products-menu-header-title {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  padding: var(--spacing-xs);
  transition: 0.5s ease;
}

.products-menu-header-title:hover {
  cursor: pointer;
  background-color: var(--primary-100);
  transition: 0.5s ease;
}

.products-menu-header-arrow {
  transition: 0.5s ease;
}

.products-menu-header-title-open {
  background-color: var(--primary-200);
  transition: 0.5s ease;
}

.products-menu-grid {
  transition: 0.5s ease;
}

.products-menu-delete-icon {
  padding: var(--spacing-2xs);
  transition: 0.5s ease;
}

.products-menu-delete-icon:hover {
  background-color: var(--red-100);
  cursor: pointer;
  transition: 0.5s ease;
}

.delete-product-modal-content-container {
  max-width: 400px;
  background-color: var(--neutral-100);
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.delete-product-modal-buttons {
  display: flex;
  gap: var(--spacing-md);
}

.add-product-modal-container {
  padding: var(--spacing-md);
  box-shadow: var(--shadow-1);
  /* border: 1px solid var(--primary-600); */
  background-color: var(--primary-700);
}

.add-product-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.add-product-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-xs);
}

.add-product-category-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
}

.add-product-category-button {
  background-color: var(--primary-100);
  border: none;
  border-bottom: 1.5px solid var(--primary-700);
  color: var(--primary-700);
  padding: var(--spacing-xs);
  transition: 0.5s ease;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  gap: var(--spacing-2xs);
}

.add-product-category-button:hover,
.add-product-dropdown-content label:hover {
  cursor: pointer;
}

.add-product-category-input {
  color: var(--primary-700);
}

.add-product-category-dropdown {
  position: relative;
  display: inline-block;
}
.add-product-dropdown-content {
  background-color: var(--primary-100);
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 300px;
  overflow-y: scroll;
}

.add-product-dropdown-content label {
  display: block;
  margin-top: 10px;
}

.add-product-price-container {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.add-product-price-parent input::before {
  content: "$";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  display: flex;
}

.add-product-unit {
  color: var(--primary-300);
  margin-top: auto;
  padding-bottom: var(--spacing2xs);
  font-size: var(--font-2xs);
}

.add-product-select-image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
}

.add-product-image-buttons {
  display: flex;
}

.add-product-select-image-container figure {
  width: 50%;
  margin: 0;
  max-width: 150px;
  aspect-ratio: 1 / 1;
}

.add-product-select-image-container figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.add-product-select-image-container input {
  padding-left: var(--spacing-md);
  display: flex;
  width: 50%;
  height: fit-content;
}

.dependencies-container {
  background-color: var(--primary-800);
  padding: var(--spacing-xs);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  margin-top: var(--spacing-xs);
}

.dependencies-container-header {
  display: flex;
  justify-content: space-between;
}

.dependencies-container-header:hover {
  cursor: pointer;
  background-color: var(--primary-700);
}

.dependencies-container-header div {
  color: var(--neutral-100);
  font-weight: 600;
  font-size: var(--font-xs);
  margin: 0;
}

.dependency-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
}

.dependency-title {
  color: var(--neutral-100);
  font-weight: 400;
  font-size: var(--font-xs);
  margin: 0;
}

.dependency-input-container {
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
  color: var(--primary-300);
}

.add-product-buttons-container {
  display: flex;
  gap: var(--spacing-2xs);
  margin-top: var(--spacing-xs);
}

.dashboard-users-header {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 1024px) {
  .admin-menu-container {
    flex-direction: row;
    margin: auto;
    gap: calc(var(--spacing-3xl) * 2);
  }

  .admin-menu-header {
    width: fit-content;
    margin: 0;
  }

  .admin-menu-lists-container {
    width: fit-content;
    margin: 0;
  }

  .admin-menu-list {
    width: 150px;
  }

  .add-product-form-container {
    flex-direction: row;
  }

  .add-product-column {
    width: 32%;
  }
}
