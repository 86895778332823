.landing-banner-container {
  background-color: var(--primary-700);
  background-image: url("../images/landingpage-background.jpg");
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: var(--spacing-2xl);
}

.landing-banner-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-sm);
  width: 100%;
  padding: var(--spacing-sm);
  max-width: 900px;
}

.landing-grid-item {
  padding: var(--spacing-sm);
  transition: 0.5s ease;
  position: relative;
  z-index: 1;
  display: flex;
  border-image: linear-gradient(
      140deg,
      var(--primary-300) 0%,
      var(--primary-700)
    )
    1;
  border-width: 1px;
  border-style: solid;
  flex-direction: column;
  gap: var(--spacing-sm);
  overflow: hidden;
  text-decoration: none;
}

.landing-banner-icon {
  position: absolute;
  bottom: -50px;
  right: -50px;
  z-index: -1;
}

.landing-grid-item::before {
  background: var(--primary-gradient-500);
  box-shadow: var(--shadow-1);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -1;
}

.landing-grid-item:hover {
  background: var(--primary-700);
  cursor: pointer;
  transition: 0.5s ease;
}

.landing-grid-item-3::before {
  background: var(--green-gradient-500);
  opacity: 1;
  transition: 0.5s ease;
}

.landing-grid-item-3:hover::before {
  background: var(--green-500);
  transition: 0.5s ease;
}
.landing-grid-item-4::before {
  background: var(--ocean-gradient-500);
  opacity: 1;
  transition: 0.5s ease;
}

.landing-grid-item-4:hover::before {
  background: var(--ocean-500);
  transition: 0.5s ease;
}

.landing-banner-animation-container {
  display: none;
}

.landing-grid-item-2 {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 3;
}
.landing-grid-item-3 {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
}
.landing-grid-item-4 {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
}
.landing-grid-item-5 {
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 3;
}
.landing-grid-item-6 {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 3;
}

.landing-banner-info-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding-left: var(--spacing-xs);
  padding-top: var(--spacing-xs);
}

@media only screen and (min-width: 1024px) {
  .landing-banner-section {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: var(--spacing-sm);
    padding: var(--spacing-lg);
    width: auto;
    margin-right: auto;
  }

  .landing-banner-animation-container {
    display: flex;
    width: 25%;
    height: auto;
    max-height: 600px;
    margin-top: auto;
  }

  .landing-banner-animation-container > figure {
    margin: 0;
    display: flex;
  }

  .landing-banner-animation-container > figure > img {
    align-self: center;
    height: 100%;
  }

  .landing-grid-item-2 {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .landing-grid-item-3 {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .landing-grid-item-4 {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .landing-grid-item-5 {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 5;
  }
  .landing-grid-item-6 {
    grid-row-start: 2;
    grid-row-end: 5;
    grid-column-start: 3;
    grid-column-end: 5;
  }
}
