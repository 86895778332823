#header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: var(--primary-gradient-500);
  padding: var(--spacing-xs) var(--spacing-lg);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  box-shadow: var(--shadow-1);
}
#header-small {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: var(--primary-gradient-500);
  padding: var(--spacing-xs) var(--spacing-lg);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  box-shadow: var(--shadow-1);
}
.header-figure {
  max-width: 150px;
  width: 25%;
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
}

#header-small .header-figure {
  max-width: 100px;
}

.header-img {
  max-width: inherit;
  text-decoration: none;
}

#header-small .header-img {
  max-width: 100px;
  display: flex;
}

.header-img > img {
  max-width: inherit;
}

#header-small .header-img > img {
  max-width: 100px;
}

#header-main-menu {
  display: none;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
}

#header-main-menu > li,
.menu__box > li {
  list-style: none;
  font-size: var(--font-sm);
  font-weight: 300;

  display: block;
  position: relative;
  overflow: hidden;
  padding: 5px 0px;
}

#header-main-menu > li::before,
.menu__box > li::before {
  content: attr(data-text);
  position: absolute;
  bottom: -100%;
  background: url("../images/hover-wave.svg");
  background-repeat: repeat-x;
  animation: menu-hover-animation 2s linear infinite;
  width: 100%;
  height: 5px;
  z-index: 1;
  transition: 0.5s ease;

  margin-bottom: 5px;
}

@keyframes menu-hover-animation {
  0% {
    background-position: 0px;
  }
  100% {
    background-position: 50px;
  }
}

#header-main-menu > li:hover:before,
.menu__box > li:hover:before {
  transition: 0.5s ease;
  bottom: -2px;
}

#header-main-menu > li > a,
.menu__box > li > a {
  color: var(--neutral-100);
  text-decoration: none;
}

#header-side-menu {
  max-width: 150px;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: flex-end;
  color: var(--neutral-100);
}

.header-loggedin-container {
  position: relative;
}

.header-signout-container {
  position: absolute;
  right: -40%;
  bottom: -96px;
  flex-direction: column;
  display: flex;
  gap: var(--spacing-xs);
  padding: var(--spacing-2xs);
  border: 10px solid var(--neutral-100);
  border-radius: 10px;
  background-color: var(--neutral-100);
  justify-content: right;
}

.header-signout-email {
  color: var(--primary-700);
  margin: 0;
}

.header-signout-button {
  background-color: var(--red-500);
  display: flex;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs);
  border-radius: 6px;
  width: fit-content;
  align-self: center;
}

.header-signout-button:hover {
  cursor: pointer;
}

/* LANGUAGE SELECTOR */

#language-selector {
  position: relative;
}

#language-selector figure {
  text-align: center;
  display: flex;
  margin: 0;
}

#language-selector figure > .language-selector-circle {
  position: absolute;
}

#language-selector figure > img {
  margin: auto;
}

#language-selector figure:hover {
  cursor: pointer;
}

#language-selector-menu {
  display: flex;
  position: absolute;
  background-color: var(--neutral-100);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: 12px;
  box-shadow: var(--shadow-1);
  margin-top: var(--spacing-xs);
  left: -185%;
}

.header-user-icon {
  flex: none;
  transition: 0.5s ease;
}

.header-user-icon:hover {
  opacity: 0.6;
  cursor: pointer;
  transition: 0.5s ease;
}

.login-screen-container {
  width: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  height: calc(100% - 48px);
  z-index: 10;
  display: flex;
  justify-content: right;
  overflow: hidden;
}

.login-screen-content-container {
  width: 95%;
  max-width: 500px;
  height: 100%;
  background-color: var(--neutral-100);
  display: flex;
  justify-content: center;
  padding-top: calc(var(--spacing-2xl) * 2);
  position: relative;
}

.login-screen-content-box {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 300px;
  gap: var(--spacing-lg);
}

.login-header {
  position: absolute;
  top: var(--spacing-lg);
  left: var(--spacing-lg);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.login-body {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  justify-content: right;
}

.login-header-arrow:hover {
  background-color: var(--primary-200);
  cursor: pointer;
}

.login-body .login-show-password-button,
.login-body .login-show-password-button:hover {
  background-color: unset;
  font-size: var(--font-2xs);
  padding-left: 30px;
  display: inline;
  width: fit-content;
  align-self: flex-end;
}

.login-body .login-show-password-button .checkmark {
  top: var(--spacing-3xs);
  width: 20px;
  height: 20px;
}

.login-body .login-show-password-button .checkmark::after {
  left: 6px;
  top: 2px;
}

#side-menu-hamburger {
  cursor: pointer;
  display: flex;
  justify-content: center;

  position: fixed;
}

#menu__toggle {
  opacity: 0;
  width: 32px;
  height: 32px;
  margin: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  right: 0 !important;
}
.menu__btn {
  position: fixed;
  justify-content: center;
  right: 25px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 10;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  top: 18px;
  background-color: var(--neutral-100);
  transition-duration: 0.5s;
}
.menu__btn > span::before {
  content: "";
  top: -10px;
}
.menu__btn > span::after {
  content: "";
  top: 10px;
}
.menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  /* width: 200px; */
  height: 100vh;
  margin: 0;
  padding: 80px 40px;
  list-style: none;
  background: var(--primary-gradient-500);
  box-shadow: var(--shadow-1);
  transition-duration: 0.5s;
  z-index: 9;
}
.menu__item {
  display: block;
  padding: 24px 24px 6px;
  color: var(--white);
  font-size: 20px;
  font-weight: 300;
  text-decoration: none;
  transition-duration: 0.5s;
  text-align: center;
  width: fit-content;
  margin: auto;

  position: relative;
  overflow: hidden;
}
.menu__item:before {
  content: "";
  position: absolute;
  left: -100%;
  bottom: 0;
  background-color: var(--yellow);
  height: 1px;
  width: 70%;
  margin-left: 15%;
  z-index: -1;
  transition: 0.5s ease;
}

.menu__item:hover:before {
  transition: 0.5s ease;
  left: 0;
}

.menu__item-active:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--yellow);
  height: 1px;
  width: 70%;
  margin-left: 15%;
  z-index: -1;
}

.menu__socials_container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

@media only screen and (min-width: 1024px) {
  .menu__box {
    width: 500px;
  }

  #header-main-menu {
    display: flex;
  }

  #side-menu-hamburger {
    display: none;
  }
}
