.footer-container {
  background-image: url("../images/landingpage-background.jpg");
  display: flex;
  flex-direction: column;
  justify-content: top;
  width: 100%;
  align-items: center;
}

.footer-content-container {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-content-container > div {
  max-width: 400px;
  padding: var(--spacing-md);
  margin-bottom: auto;
}

@media only screen and (min-width: 1024px) {
  .footer-content-container {
    flex-direction: row;
  }

  .footer-content-container > div {
    padding: var(--spacing-xl);
  }
}
