:root {
  /* Primary colors */
  --primary-100: #f1f5ff;
  --primary-200: #dce6ff;
  --primary-200-25: #dce6ff25;
  --primary-300: #8a9ed1;
  --primary-300-25: #8a9ed125;
  --primary-300-50: #8a9ed150;
  --primary-300-75: #8a9ed175;
  --primary-500: #0075ff;
  --primary-600: #0054b7;
  --primary-700: #013571;
  --primary-700-50: #01357150;
  --primary-800: #001d3f;

  /* Neutral colors */
  --neutral-100: #fff;
  --neutral-200: #f5f5f5;
  --neutral-225: #ececec;
  --neutral-250: #cdcdcd;
  --neutral-275: #bfbfbf;
  --neutral-300: #999999;
  --neutral-400: #6b6b6b;
  --neutral-800: #2f2f2f;
  --neutral-900: #000;

  /* Green colors */
  --green-100: #e8ffdd;
  --green-400: #8bf266;
  --green-500: #3dbe00;
  --green-550: #319900;
  --green-600: #2a8500;

  /* Red colors */
  --red-100: #ffefef;
  --red-500: #ff2b2b;

  /* Ocean colors */
  --ocean-500: #00bbc8;
  --ocean-600: #008d97;

  /* Gradients */
  --primary-gradient-500: linear-gradient(
    to right,
    var(--primary-700),
    var(--primary-600)
  );
  --primary-gradient-200: linear-gradient(
    to right,
    #a5e9ff,
    var(--primary-100)
  );
  --green-gradient-400: linear-gradient(to right, #5bdd2c, #35a600);
  --green-gradient-500: linear-gradient(
    to right,
    var(--green-500),
    var(--green-600)
  );
  --ocean-gradient-500: linear-gradient(
    to right,
    var(--ocean-500),
    var(--ocean-600)
  );
  --neutral-gradient-100: linear-gradient(
    to right,
    var(--neutral-100),
    var(--neutral-200)
  );

  /* Spacing */
  --spacing-4xs: 0.075rem;
  --spacing-3xs: 0.125rem;
  --spacing-2xs: 0.25rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-2xl: 2.5rem;
  --spacing-3xl: 3rem;
  --spacing-4xl: 3.5rem;
  --spacing-5xl: 4rem;
  --spacing-6xl: 5rem;

  /* Font sizes */
  --font-3xs: 0.6rem;
  --font-2xs: 0.8rem;
  --font-xs: 1rem;
  --font-sm: 1.25rem;
  --font-md: 1.5rem;
  --font-lg: 2rem;
  --font-xl: 2.5rem;
  --font-2xl: 3rem;
  --font-3xl: 7rem;

  /* Shadow */
  --shadow-1: 0 8px 40px rgb(0 0 0 / 10%);

  /* Default font families */
  --font-sans: "Poppins", ui-sans-serif, system-ui, -apple-system,
    blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  --font-serif: ui-serif, georgia, cambria, "Times New Roman", times, serif;
}

html {
  /* nog te uit commentaar zetten: */
  /* overflow-y: hidden; */
}

body {
  margin: 0;
  font-family: var(--font-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-input-container,
.PhoneInput {
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
}

.PhoneInput {
  justify-content: left;
  width: 100%;
  background-color: var(--primary-100);
  border-bottom: 1.5px solid var(--primary-700);
}

.PhoneInputCountry {
  /* border-bottom: 1.5px solid var(--primary-700); */
  padding-left: var(--spacing-xs);
}

.text-input-label {
  color: var(--primary-700);
  font-size: var(--font-xs);
  font-weight: 400;
  margin-bottom: auto;
  padding-top: var(--spacing-2xs);
  margin-right: auto;
}

.text-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-2xs);
}

.text-input-container > p {
  text-align: left;
  margin-right: auto;
}

.text-input,
.PhoneInputInput {
  background-color: var(--primary-100);
  border: none;
  border-bottom: 1.5px solid var(--primary-700);
  color: var(--primary-700);
  padding: var(--spacing-xs);
  transition: 0.5s ease;
  width: 100%;
  box-sizing: border-box;
}

.overview-personal-data-form .text-input,
.overview-personal-data-form .PhoneInput {
  background-color: var(--primary-300-75);
  color: var(--primary-100);
}

.overview-personal-data-form .PhoneInputInput {
  background-color: unset;
  color: var(--primary-100);
}
.overview-personal-data-form .text-input-container option {
  background-color: var(--primary-700);
  color: var(--primary-100);
}

.PhoneInputInput {
  border-bottom: unset;
}

.text-input::placeholder,
.PhoneInputInput::placeholder {
  color: var(--primary-300);
}

.text-input:focus-visible,
.PhoneInputInput:focus-visible {
  outline: 2px solid var(--primary-500);
  /* border-bottom: 1.5px solid var(--primary-700); */
  border: none;
  transition: 0.5s ease;
}

.primary-button {
  background-color: var(--primary-600);
  color: var(--neutral-100);
  border: none;
  padding: var(--spacing-xs);
  color: var(--neutral-100);
  font-size: var(--font-xs);
  font-weight: 600;
  transition: 0.5s ease;
}

.primary-button:hover {
  background-color: var(--primary-300);
  cursor: pointer;
  transition: 0.5s ease;
}

.primary-button:disabled {
  background-color: var(--neutral-225);
  color: var(--neutral-250);
  border: none;
  padding: var(--spacing-xs);
  color: var(--neutral-300);
  font-size: var(--font-xs);
  font-weight: 600;
  transition: 0.5s ease;
}
.secondary-button {
  background-color: var(--primary-100);
  color: var(--neutral-100);
  border: 2px solid var(--primary-600);
  padding: var(--spacing-xs);
  color: var(--primary-600);
  font-size: var(--font-xs);
  font-weight: 600;
  transition: 0.5s ease;
}

.secondary-button:hover {
  background-color: var(--primary-700);
  cursor: pointer;
  transition: 0.5s ease;
  color: var(--neutral-100);
  border: 2px solid var(--primary-700);
}

.secondary-button:disabled {
  background-color: var(--neutral-225);
  color: var(--neutral-250);
  border: none;
  padding: var(--spacing-xs);
  color: var(--neutral-300);
  font-size: var(--font-xs);
  font-weight: 600;
  transition: 0.5s ease;
}

.step-transition-enter {
  opacity: 0.01;
  transform: translate(0, -10px);
}

.step-transition-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 300ms ease-in;
}

.step-transition-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.step-transition-exit-active {
  opacity: 0.01;
  transform: translate(0, 10px);
  transition: all 300ms ease-in;
}

.socials-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
}

.socials-container a {
  color: var(--neutral-100);
  text-decoration: none;
  font-weight: 300;
  font-size: var(--font-2xs);
  display: flex;
  align-items: center;
  gap: var(--spacing-2xs);
  opacity: 1;
  transition: 0.5s ease;
}

.socials-container a:hover {
  opacity: 0.6;
  transition: 0.5s ease;
}
